:root {
    color-scheme: only light;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@keyframes animateContainer {
    from {
      transform: translateX(calc(0%));
    }
    to {
      transform: translateX(calc(-100%));
    }
  }
  
@keyframes animateContainerHalf {
    from {
      transform: translateX(calc(100%));
    }
    to {
      transform: translateX(calc(-100%));
    }
  }
  